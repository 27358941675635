// Float the "About" page image so the text content wraps around it. Requires the
// image to be the first paragraph of the page content
.s-about-page p:nth-of-type(1) {
  float: right;
  margin-bottom: .25em;
  margin-left: .5em;
  width: 12rem;

  @media screen and (min-width: 37.5rem) {
    width: 16rem;
  }

  @media screen and (min-width: 60rem) {
    width: 20rem;
  }
}
