// ----------
//
// c-page-jumbotron
//
// ----------

.c-page-jumbotron {
  display: grid;
  grid-template-columns: [full-start] minmax(1rem, 1fr) [content-start] minmax(0, 80ch) [content-end] minmax(1rem, 1fr) [full-end];
  grid-template-rows:
    [full-start]
    30rem
    [title-start]
    5rem
    [title-end hook-start]
    5rem
    [hook-end full-end];
  grid-column: full;
  height: 40rem;
  position: relative;
  z-index: 1;
}

.c-page-jumbotron__gradient {
  background-image:
    linear-gradient(
      to bottom,
      transparent,
      transparent
    );
  grid-column: full;
  grid-row: full;
  z-index: -1;
}

.c-page-jumbotron__image {
  display: block;
  grid-column: full;
  grid-row: full;
  height: 100%;
  object-fit: cover;
  width: 100%;
  z-index: -2;
}

.c-page-jumbotron__title {
  color: $c-white;
  grid-column: content;
  grid-row: title;
  font-size: calc(1vw + 2.25rem);
  font-weight: 700;
  letter-spacing: .063em;
  margin: 0;
  padding-left: .063em;
  text-shadow: 0 0 .25em $c-primary;
  text-transform: uppercase;
}

.c-page-jumbotron__hook {
  color: $c-white;
  grid-column: content;
  grid-row: hook;
  font-family: $f-serif;
  font-size: calc(1vw + 1rem);
  font-style: italic;
  margin: 0;
  align-self: start;
  text-shadow: 0 0 .125em $c-primary;
}
