// ----------
//
// elements
//
// ----------

html,
body {
  height: 100%;
}

html {
  background-color: $c-white;
  color: $c-primary;
  overflow-y: scroll;
}

body {
  display: flex;
  flex-flow: column nowrap;
}

body main {
  flex-grow: 1;
}

p {
  margin-top: 0;
}

a {
  color: $c-primary;
  transition: opacity 200ms ease-out;
}

a:hover,
a:focus {
  color: currentColor;
  opacity: .75;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul > li {
  padding: .5rem 0;
}

ul > li::before {
  content: '\2756';
  display: inline-block;
  opacity: .75;
  padding-right: .5rem;
}

ul.special-list + p {
  margin-top: 2.25em;
}
