// ----------
//
// o-site-main
//
// ----------

.o-site-main {
  display: grid;
  grid-template-columns: [full-start] minmax(1rem, 1fr) [content-start] minmax(0, 80ch) [content-end] minmax(1rem, 1fr) [full-end];
  grid-auto-rows: min-content;
  flex-grow: 1;
}

.o-site-main > * {
  grid-column: content;
  margin-top: 0;
  margin-bottom: 0;
}

.o-site-main > * + * {
  margin-top: 1em;
}
