// ----------
//
// s-page-content
//
// ----------

.s-page-content h2 {
  font-family: $f-serif;
  font-size: 2.25rem;
  font-style: italic;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: .83em;
}

.s-page-content h2::after {
  background-color: $c-accent;
  content: '';
  display: block;
  height: 2px;
  margin-top: .63em;
  width: 3.375em;
}
