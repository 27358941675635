// ----------
//
// c-page-heading
//
// ----------

.c-page-heading {
  font-family: $f-serif;
  font-size: 2.25rem;
  font-style: italic;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: .83em;
}

.c-page-heading::after {
  background-color: $c-accent;
  content: '';
  display: block;
  height: 2px;
  margin-top: .63em;
  width: 3.375em;
}
