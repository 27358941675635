.s-testimonial + .s-testimonial {
  margin-top: 2.25rem;
}

.s-testimonial p {
  margin-bottom: .5em;
}

.s-testimonial em {
  font-family: $f-serif;
  font-size: 1.25em;
}
