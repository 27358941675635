// ----------
//
// c-button
//
// ----------

.c-button {
  background-color: transparent;
  border: .063em solid $c-primary;
  border-radius: .25rem;
  color: $c-primary;
  display: inline-block;
  font-family: $f-sans;
  font-weight: 500;
  line-height: 1;
  padding: 1em 1.5em;
  text-decoration: none;
  text-transform: uppercase;
  transition:
    background-color 200ms ease-out,
    color 200ms ease-out,
    opacity 200ms ease-out;
  white-space: nowrap;
}

.c-button:hover {
  background-color: $c-primary;
  color: $c-white;
  opacity: .85;
  text-decoration: none;
}
