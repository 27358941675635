// ----------
//
// c-image-gallery
//
// ----------

$gallery-gap: 1rem;

.c-image-gallery {
  columns: 12rem;
  column-gap: $gallery-gap;
  grid-column: full;
  padding: $gallery-gap;

  @media screen and (min-width: 40rem) {
    columns: 16rem;
  }

  @media screen and (min-width: 90rem) {
    columns: 24rem;
  }
}

.c-image-gallery > img {
  border-radius: .25rem;
  display: block;
  height: auto;
  margin-bottom: $gallery-gap;
  width: 100%;
}
