// ----------
//
// o-box
//
// ----------

.o-box {
  padding: 1rem 0;
}

.o-box--large {
  padding: 2.25rem 0;
}

.o-box--larger {
  padding: 3.375rem 0;
}

.o-box--huge {
  padding: 7.594rem 0;
}

.o-box > *:first-child {
  margin-top: 0;
}

.o-box > *:last-child {
  margin-bottom: 0;
}
