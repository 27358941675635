// ----------
//
// c-social-media-list
//
// adapted from the 'minima' theme
//
// ----------

.c-social-media-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.c-social-media-list li {
  flex: 0 0 auto;
}

.c-social-media-list li::before {
  display: none;
}


.c-social-media-list a {
  color: currentColor;
  display: block;
}

.c-social-media-list .svg-icon {
  fill: currentColor;
  height: auto;
  max-height: 3.375rem;
  max-width: 3.375rem;
  width: 100%;
}
