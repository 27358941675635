.c-post-list {
  list-style: none;
  padding-left: 0;
}

.c-post-list > li + li {
  margin-top: 2.25rem;
}

.c-post-list > li > *:first-child {
  margin-top: 0;
}

.c-post-list > li > *:last-child {
  margin-bottom: 0;
}

.c-post-list > li > h3 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: .25em;
}

.c-post-list > li > h3 > small {
  font-family: "Bodoni 16", serif;
  font-size: 1rem;
  font-style: italic;
  font-variant-numeric: tabular-nums
}

.c-post-list > li > div {
  display: flow-root;
}

.c-post-list > li > div > img {
  display: block;
  float: right;
  margin: 0 .5rem .5rem;
  max-height: 24rem;
  max-width: 24rem;
}
