// ----------
//
// c-divider
//
// ----------

.c-divider {
  background-color: currentColor;
  border: 0;
  display: block;
  height: 1px;
  margin: 1.5rem auto;
  opacity: .5;
  width: 80%;
}
