// ----------
//
// o-site-footer
//
// ----------

.o-site-footer {
  background-color: $c-primary;
  color: rgba($c-white, .7);
  margin-top: 2.25rem;
  padding: 1rem;
}
