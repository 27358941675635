// ----------
//
// c-google-map-embed
//
// ----------

.c-google-map-embed {
  height: 0;
  overflow: hidden;
  padding-bottom: 30%;
  position: relative;
}

.c-google-map-embed iframe {
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
}
