// ----------
//
// variables
//
// ----------

// colors
// ref: https://coolors.co/ff6f61-263056-b5bab6-a79b82-000000
$c-white: #fefefe;
$c-primary: #3f413f;
$c-accent: #ff6f61;

// fonts
$f-sans: 'Quattrocento Sans',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
$f-serif: 'Bodoni 16', serif;
