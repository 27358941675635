// ----------
//
// text utilities
//
// ----------

.text-lead {
  font-size: calc(0.5vw + 0.75rem);
}

.text-center {
  text-align: center;
}
