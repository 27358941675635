// ----------
//
// c-image-banner
//
// ----------

.c-image-banner {
  display: flex;
  grid-column: full;
  height: 25vh;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  overflow-x: hidden;
}

.c-image-banner__image {
  display: block;
  flex: 1 1 20%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
