// ----------
//
// flex-grid
//
// ----------

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em
}

.flex-grid > * {
  flex: 1 0 15em;
  margin: 0.5em;
  min-width: 0;
}

.flex-grid--reverse {
  flex-direction: row-reverse;
}
