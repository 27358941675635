// ----------
//
// c-plan-event-form
//
// ----------

.c-plan-event-form {
  margin: 2.25rem auto 0;
  max-width: 40ch;
  width: 100%;
}

.c-plan-event-form label {
  display: block;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
}

.c-plan-event-form input[type],
.c-plan-event-form select {
  border: 1px solid $c-primary;
  border-radius: .25rem;
  display: block;
  padding: .5rem;
  width: 100%;
}

.c-plan-event-form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
}

.c-plan-event-form input:hover,
.c-plan-event-form select:hover {
  background-color: rgba($c-primary, .1);
}

.c-plan-event-form input:focus,
.c-plan-event-form select:focus,
.c-plan-event-form button:focus {
  box-shadow: 0 0 0 .125em rgba($c-primary, .5);
  outline: none;
}
