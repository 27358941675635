// ----------
//
// c-site-nav
//
// ----------

.c-site-nav {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.c-site-nav__logo {
  display: block;
  margin-bottom: 1em;
  max-height: 5.063rem;
  max-width: 100%;
}

.c-site-nav__toggle {
  font-size: .85em;
  margin: .5rem 0;
}

.c-site-nav__toggle > * {
  vertical-align: middle;
}

.c-site-nav__toggle svg {
  display: inline-block;
  height: 1em;
  margin-right: .25em;
  width: 1em;
}

.c-site-nav__list {
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.c-site-nav__list.is-open {
  display: flex;
}

.c-site-nav__item::before {
  display: none;
  margin: 0;
  padding: 0;
}

.c-site-nav__item + .c-site-nav__item {
  margin-top: 1rem;
}

.c-site-nav__link {
  color: $c-primary;
  letter-spacing: .063em;
  opacity: .75;
  padding-left: .063em;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 200ms ease-out;
}

.c-site-nav__link:hover {
  opacity: 1;
  text-decoration: underline;
}

.c-site-nav__link.is-current {
  font-weight: 700;
  opacity: 1;
}

.c-site-nav__link--home {
  opacity: 1;
}

.c-site-nav__link[href^="http"]::after {
  content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><g fill="none" fill-rule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"/></g></svg>');
  display: inline-block;
  height: 1em;
  margin-left: .25em;
  width: 1em;
}

@media screen and (min-width: 60rem) {
  .c-site-nav__toggle {
    display: none !important;
  }

  .c-site-nav__list {
    display: flex !important;
    flex-direction: row;
  }

  .c-site-nav__item + .c-site-nav__item {
    margin-top: 0;
    margin-left: 2.25rem;
  }
}
