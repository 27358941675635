// ----------
//
// c-galleries-index
//
// ----------

.c-galleries-index {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -1rem;
}

.c-galleries-index__link {
  display: block;
  flex: 1 1 23rem;
  letter-spacing: .063em;
  margin: 1rem;
  padding-left: .063em;
  text-decoration: none;
  text-transform: uppercase;
  transition: none;

  &:hover,
  &:focus {
    opacity: 1;
    text-decoration: underline;
  }
}

.c-galleries-index__link:hover .c-galleries-index__image,
.c-galleries-index__link:focus .c-galleries-index__image {
  box-shadow: 0 4px 8px rgba($c-primary, .35);
}

.c-galleries-index__image {
  display: block;
  height: 16rem;
  margin: 0 auto;
  object-fit: cover;
  object-position: center;
  transition: box-shadow 200ms ease-out;
  width: 23rem;
}

.c-galleries-index__text {
  display: block;
  margin-top: .5em;
  text-align: center;
}
